<template>
  <div class="content">
    <el-row>
      <el-col :span="5">
        <div class="TreeBox">
          <el-tabs v-model="activeName"  @tab-click="handleClick">
            <!-- <el-tab-pane label="非居民片区管理" name="1">
              <div class="clears">
                <el-input
                    v-model="filterText"
                    prefix-icon="el-icon-search"
                    size="mini"
                    placeholder="请输入地区名称关键字"
                    clearable
                    @clear="searchTree"
                >
                </el-input>
                <el-button type="primary" size="mini" class="buttons" @click="searchTree">查询</el-button>
              </div>
              <el-tree
                  ref="tree"
                  class="filter-tree"
                  node-key="id"
                  :data="treedata"
                  :props="defaultProps"
                  :default-expanded-keys="[8]"
                  :filter-node-method="filterNode"
                  @node-click="clickTree"
              >
              </el-tree>
            </el-tab-pane> -->
            <el-tab-pane label="片区管理" name="0">
              <div class="clears">
                <el-input
                    v-model="userfilterText"
                    prefix-icon="el-icon-search"
                    size="mini"
                    placeholder="请输入地区名称关键字"
                    clearable
                    @clear="userSearchTree"
                >
                </el-input>
                <el-button type="primary" size="mini" class="buttons" @click="userSearchTree">查询</el-button>
              </div>
              <el-tree
                  ref="userTree"
                  class="filter-tree"
                  node-key="id"
                  :data="userTreedata"
                  :props="defaultProps"
                  :default-expanded-keys="defaultKeys"
                  :filter-node-method="UserfilterNode"
                  :highlight-current='true'
                  @node-click="UserclickTree"
              >
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="19" v-if="!statusdialogVisible">
        <!-- 非民 -->
        <div class="TableBox" v-if="activeName==1" >
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="行政地址">
              <el-select
                v-model="pageParams.province"
                clearable
                filterable
                size="mini"
                style="width:140px;margin-right: 10px;"
                class="EquipmentMarginLeft"
                placeholder="请选择省"
                @change="getRoleIdsCity"
              >
                <el-option
                  v-for="item in roleArray_group"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="pageParams.city"
                clearable
                filterable
                :disabled='!pageParams.province'
                size="mini"
                style="width:140px;margin-right: 10px;"
                class="EquipmentMarginLeft"
                placeholder="请选择市"
                @change="getRoleIdsDistrict"
              >
                <el-option
                  v-for="item in roleArray_city"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="pageParams.district"
                clearable
                filterable
                size="mini"
                :disabled='!pageParams.city'
                style="width:140px;margin-right: 30px;"
                class="EquipmentMarginLeft"
                placeholder="请选择区/县"
                @change="search"
              >
                <el-option
                  v-for="item in roleArray_area"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-select
                v-model="pageParams.generalStatus"
                clearable
                size="mini"
                style="width:150px"
                class="EquipmentMarginLeft"
                placeholder="请选择街道/乡"
              >
                <el-option
                  v-for="item in roleArray_village"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
              <!-- <el-cascader
              size="mini"
                v-model="pageParams.generalStatuslue"
                :options="roleArray_group"
                @change="handleChange"></el-cascader> -->
            </el-form-item>
            <el-form-item label="片区名称">
              <el-input
                v-model="pageParams.addrName"
                size="mini"
                clearable
                style="width:200px"
                placeholder="片区名称"
                class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-button size="mini" type="primary" @click="search" style="height:30px;margin-top:5px"

              >查询</el-button
            >
            <el-button size="mini" type="warning" style="height:30px;margin-top:5px" @click="restSearch" >重置</el-button>
          </el-form>
          <div class="TableTool">
            <div class="TableToolLeft">
              <el-button type="text" size="mini" @click="delUser" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;"  v-if="$anthButtons.getButtonAuth('fmpqplsc')">批量删除</el-button>
              <el-button type="text" size="mini" @click="addUser" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;"  v-if="$anthButtons.getButtonAuth('fmpqtj') && !addbutton">添加</el-button>
            </div>
            <!-- <div class="TableToolRight">
              <el-button icon="el-icon-folder-opened" @click="onDownExcel"></el-button>
            </div> -->
          </div>
          <el-table
            :data="userList"
            height="calc(100vh - 350px)"
            style="margin-top: 10px"
            border
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
            @selection-change="handleSelectionChange"
          >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
            <el-table-column type="index" label="序号"  width="80"></el-table-column>
            <el-table-column  prop="id" label="ID"  width="80"></el-table-column>
            <el-table-column
              prop="addrName"
              label="片区（小区/村庄）名称"
            >
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
                <el-tooltip class="item" effect="dark" :content="row.addrName" placement="top-start">
                  <div class="twoLines">{{row.addrName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="addrDeptName"
              label="负责部门"
            ></el-table-column>
            <el-table-column

              prop="town"
              label="行政地址"
            ></el-table-column>
            <el-table-column

              prop="sortNum"
              label="排序"
              sortable
              width="100px"
            >
              <template slot-scope="{ row }">
                <!-- parseInt(Math.abs(row.sortNum)) -->
                <el-input v-model.number="row.sortNum" 
                placeholder="请输入排序"  
                type="number"
                          @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? 0: parseInt(row.sortNum))"
                @change="sortNumber(row,1)"></el-input>
              </template>
            </el-table-column>
            <el-table-column  align="left" prop="State" label="操作" width="233px">
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button
                      v-if="$anthButtons.getButtonAuth('fmpqsc')"
                    class="mr10"
                    type="danger"
                    size="mini"
                    style="background-color: #EE2D2D"
                    @click="del(row.id)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="pageParams.size"
              :current-page="pageParams.current"
              @size-change="handleSizeChange"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 民用省市 -->
        <div class="TableBox" v-show="activeName==0&&showEare == 1" >
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="行政地址">
              <el-select
                  v-model="userPageParams.province"
                  clearable
                  filterable
                  size="mini"
                  style="width:140px;margin-right: 10px;"
                  class="EquipmentMarginLeft"
                  placeholder="请选择省"
                  @change="peoplegetRoleIdsCity"
              >
                <el-option
                    v-for="item in roleArray_group"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                  v-model="userPageParams.city"
                  clearable
                  filterable
                  :disabled='!userPageParams.province'
                  size="mini"
                  style="width:140px;margin-right: 10px;"
                  class="EquipmentMarginLeft"
                  placeholder="请选择市"
                  @change="peoplegetRoleIdsDistrict"
              >
                <el-option
                    v-for="item in userroleArray_city"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                  v-model="userPageParams.district"
                  clearable
                  filterable
                  size="mini"
                  :disabled='!userPageParams.city'
                  style="width:140px;margin-right: 30px;"
                  class="EquipmentMarginLeft"
                  placeholder="请选择区/县"
                  @change="userSearch"
              >
                <el-option
                    v-for="item in userroleArray_area"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-select
                v-model="pageParams.generalStatus"
                clearable
                size="mini"
                style="width:150px"
                class="EquipmentMarginLeft"
                placeholder="请选择街道/乡"
              >
                <el-option
                  v-for="item in roleArray_village"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
              <!-- <el-cascader
              size="mini"
                v-model="pageParams.generalStatuslue"
                :options="roleArray_group"
                @change="handleChange"></el-cascader> -->
            </el-form-item>
            <el-form-item label="片区名称">
              <el-input
                  v-model="userPageParams.addrName"
                  size="mini"
                  clearable
                  style="width:200px"
                  placeholder="片区名称"
                  class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-button size="mini" type="primary" @click="userSearch" style="height:30px;margin-top:5px"
            >查询</el-button
            >
            <el-button size="mini" type="warning" style="height:30px;margin-top:5px" @click="userRestSearch" >重置</el-button>
          </el-form>
          <div class="TableTool">
            <div class="TableToolLeft" v-if="chucunObj.type > 2">
              <el-button type="text" size="mini" @click="peopledelUser" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;"  v-if="$anthButtons.getButtonAuth('jmpqplsc')">批量删除</el-button>
              <el-button type="text" size="mini" @click="addUser" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;"  v-if="$anthButtons.getButtonAuth('jmpqtj') && !addbuttonmin">添加</el-button>
              <el-button type="text" size="mini" @click="alterUser(1)" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        height: 30px;" >批量修改</el-button>
            </div>
          </div>
          <el-table
              :data="peopleuserList"
              height="calc(100vh - 350px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
              @selection-change="peoplehandleSelectionChange"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column type="index" label="序号"  width="80"></el-table-column>
            <el-table-column  prop="id" label="ID"  width="80"></el-table-column>
            <el-table-column
                prop="addrName"
                label="片区（小区/村庄）名称"
            >
              <!-- <template slot-scope="{row}"> -->
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
                <!-- <el-tooltip class="item" effect="dark" :content="row.addrName" placement="top-start">
                  <div class="twoLines">{{row.addrName}}</div>
                </el-tooltip>
              </template> -->
              <template slot-scope="{ row }">
                <!-- parseInt(Math.abs(row.sortNum)) -->
                <el-input v-model="row.addrName" 
                placeholder="请输入片区（小区/村庄）名称"  
                type="text"
                @change="sortNumber(row,4)"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="addrDeptName"
                label="负责部门"
            ></el-table-column>
            <el-table-column

                prop="town"
                label="行政地址"
            ></el-table-column>
            <el-table-column

                prop="sortNum"
                label="排序"
                sortable
                width="100px"
            >
              <template slot-scope="{ row }">
                <el-input v-model.number="row.sortNum" 
                placeholder="请输入排序"  
                type="number"
                          @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? 0: parseInt(row.sortNum))"
                @change="sortNumber(row,2)"></el-input>
              </template>
<!--              <template slot-scope="{ row }">-->
<!--                <el-input v-model.number="row.sortNum"-->
<!--                          placeholder="请输入排序"-->
<!--                          @input="row.sortNum= row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? parseInt(Math.abs(row.sortNum)): row.sortNum)"-->
<!--                          @change="sortNumber(row)"></el-input>-->
<!--              </template>-->
            </el-table-column>
            <el-table-column  align="left" prop="State" label="操作" width="233px">
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button class="mr10" type="primary" size="mini" @click="toquyu(row)" v-if="$anthButtons.getButtonAuth('jmpqqygl')">管理区域</el-button>
                  <el-button class="mr10" type="danger" size="mini" style="background-color: #EE2D2D" @click="peopledel(row.id)" v-if="$anthButtons.getButtonAuth('jmpqsc')">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="usertotal"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="userPageParams.size"
                :current-page="userPageParams.current"
                @size-change="userhandleSizeChange"
                @current-change="usercurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 民用区域 -->
        <div class="TableBox" v-show="activeName==0&&showEare == 2" >
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="区域名称">
              <el-input
                  v-model="areaPageParams.addrsName"
                  size="mini"
                  clearable
                  style="width:200px"
                  placeholder="区域名称"
                  class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-button size="mini" type="primary" @click="areaSearch" style="height:30px;margin-top:5px">查询</el-button>
            <el-button size="mini" type="warning" style="height:30px;margin-top:5px" @click="areaRestSearch" >重置</el-button>
          </el-form>
          <div class="TableTool">
            <div class="TableToolLeft">
              <el-button type="text" size="mini" @click="areaDelUser" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;">批量删除</el-button>
              <el-button type="text" size="mini" @click="addareaUser" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;">添加</el-button>
              <el-button type="text" size="mini" @click="alterUser(2)" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        height: 30px;" >批量修改</el-button>
            </div>
            <!-- <div class="TableToolRight">
              <el-button icon="el-icon-folder-opened" @click="onDownExcel"></el-button>
            </div> -->
          </div>
          <el-table
              :data="areaUserList"
              height="calc(100vh - 350px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
              @selection-change="handleAreaSelectionChange"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column type="index" label="序号"  width="80"></el-table-column>
            <el-table-column  prop="id" label="ID"  width="80"></el-table-column>
            <el-table-column
                prop="addrsName"
                label="区域"
            >
              <template slot-scope="{ row }">
                <!-- parseInt(Math.abs(row.sortNum)) -->
                <el-input v-model="row.addrsName" 
                placeholder="请输入片区（小区/村庄）名称"  
                type="text"
                @change="sortNumber(row,5)"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="addrName"
                label="片区（小区/村庄）名称"
            >
              <template slot-scope="{row}">
                <!-- <el-popover placement="top-start" trigger="hover" :content="row.addrName">
                  <div  slot="reference" class="twoLines">
                    {{row.addrName}}
                  </div>
                </el-popover> -->
                <el-tooltip class="item" effect="dark" :content="row.addrName" placement="top-start">
                  <div class="twoLines">{{row.addrName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                prop="sortNum"
                label="排序"
                sortable
                width="100px"
            >
              <template slot-scope="{ row }">
                <el-input v-model.number="row.sortNum" 
                placeholder="请输入排序"  
                type="number"
                          @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? 0: parseInt(row.sortNum))"
                @change="sortNumber(row,3)"></el-input>
              </template>
<!--              <template slot-scope="{ row }">-->
<!--                <el-input v-model.number="row.sortNum"-->
<!--                          placeholder="请输入排序"-->
<!--                          @input="row.sortNum= row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? parseInt(Math.abs(row.sortNum)): row.sortNum)"-->
<!--                          @change="sortNumber(row)"></el-input>-->
<!--              </template>-->
            </el-table-column>
            <el-table-column  align="left" prop="State" label="操作" width="233px">
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button class="mr10" type="primary" size="mini" @click="toquyuunit(row)">管理单元</el-button>
                  <el-button class="mr10" type="danger" size="mini" style="background-color: #EE2D2D" @click="delArea(row.id)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="areaTotal"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="areaPageParams.size"
                :current-page="areaPageParams.current"
                @size-change="areahandleSizeChange"
                @current-change="areacurrentChange"
            >
            </el-pagination>
          </div>
        </div>
         <!-- 民用单元 -->
        <div class="TableBox" v-show="activeName==0&&showEare == 3" >
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="单元名称">
              <el-input
                  v-model="unitPageParams.addrsName"
                  size="mini"
                  clearable
                  style="width:200px"
                  placeholder="单元名称"
                  class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-button size="mini" type="primary" @click="unitSearch" style="height:30px;margin-top:5px">查询</el-button>
            <el-button size="mini" type="warning" style="height:30px;margin-top:5px" @click="unitRestSearch" >重置</el-button>
          </el-form>
          <div class="TableTool">
            <div class="TableToolLeft">
              <el-button type="text" size="mini" @click="unitDelUser" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;">批量删除</el-button>
              <el-button type="text" size="mini" @click="addunitUser" style="border: 1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;">添加</el-button>
            </div>
          </div>
          <el-table
              :data="unitList"
              height="calc(100vh - 350px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
              @selection-change="handleUnitSelectionChange"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column type="index" label="序号"  width="80"></el-table-column>
            <el-table-column  prop="id" label="ID"  width="80"></el-table-column>
            <el-table-column
                prop="addrssName"
                label="单元名称"
            >
              <template slot-scope="{ row }">
                <el-input v-model="row.addrssName" 
                placeholder="请输入单元名称"  
                type="text"
                @change="sortNumber(row,7)"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="addrsName"
                label="区域名称"
            >
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.addrsName" placement="top-start">
                  <div class="twoLines">{{row.addrsName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                prop="sortNum"
                label="排序"
                sortable
                width="100px"
            >
              <template slot-scope="{ row }">
                <el-input v-model.number="row.sortNum" 
                placeholder="请输入排序"  
                type="number"
                          @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? 0: parseInt(row.sortNum))"
                @change="sortNumber(row,6)"></el-input>
              </template>
            </el-table-column>
            <el-table-column  align="left" prop="State" label="操作" width="233px">
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button class="mr10" type="danger" size="mini" style="background-color: #EE2D2D" @click="delunit(row.id)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="unitTotal"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="unitPageParams.size"
                :current-page="unitPageParams.current"
                @size-change="unithandleSizeChange"
                @current-change="unitcurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col v-if="dialogVisible" :span="19" >
        <Add ref="add" @close='close' :activeName="activeName" :pageParams='pageParams'></Add>
      </el-col>
      <el-col v-if="areadialogVisible" :span="19" >
        <AreaAdd @close='areaClose' :areaId="pid"></AreaAdd>
      </el-col>
      <el-col v-if="unitdialogVisible" :span="19" >
        <UnitAdd @close='unitClose' :areaId="unitpid"></UnitAdd>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="alterModel"
      :title='alterTitle'
      :close-on-click-modal="false"
      width="30%"
    >
    
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="社区" prop="altervalue" v-if="hierarchy == 1">
          <el-select v-model="ruleForm.altervalue" filterable placeholder="请选择社区" size="mini">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小区" prop="housingvalue"  v-if="hierarchy == 2">
          <el-select v-model="ruleForm.housingvalue" filterable placeholder="请选择小区" size="mini">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.addrName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="margin-top:10px">
          <el-button class="reset_button" @click="alterModel = false">取消</el-button>
          <el-button type="primary" @click="submitFormwork('ruleForm')">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {updateSortNum1} from "../../RequestPort/Login/LoginRequest";
import {
  updateSortNum
} from "../../RequestPort/Login/LoginRequest";
import {findTreeList,areaDropDownList,addrPageList,addrBatchDelete,arumDeptList,addrBatchUpdate,arumAddrList,addrsBatchUpdate } from "@/RequestPort/induction";
import Add from './add.vue'
import AreaAdd from './areaAdd.vue'
import UnitAdd from './unitAdd.vue'
import userHousing from './userHousing'
import {findTree} from "../../RequestPort/peopleUser/people";
export default {
  name: 'User',
  components: { Add,AreaAdd,UnitAdd },
  mixins:[userHousing],
  data() {
    return {

      defaultAll:[],
      pageParams: {
        current: 1,
        size: 100,
        phone: '',
        username: '',
        generalStatus: '',
        homeCode: '',
        userType: 2
      },
      updataUserId: null,
      form: {
        generalStatus: 1,
        roleIds: [],
        positionId: '',
        realName: '',
        username: '',
        phone: '',
        remark: '',
        homeCode: '',
        userType: 2
      },
      updataStatus: 0,
      dialogVisible: false,
      statusdialogVisible:false,
      total: 0,
      // 列表数据
      userList: [],
      // 暂存数据勾选
      userFile: [],
      // 地址下拉-省
      roleArray_group: [],
      // 市
      roleArray_city:[],
      // 区/县
      roleArray_area:[],
      // 树查询条件
      filterText: '',
      // 树列表
      treedata: [],
      // 树配置
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      rule: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 25, message: '超出最大限制', trigger: 'change' }
        ],
      },
      recursion: [],
      unitdialogVisible:false,
      addbutton:false,
      addbuttonmin:false,
      alterModel:false,
      options:[],
      ruleForm:{
        altervalue:null
      },
      rules:{
        altervalue:[{  required: true, message: '请选择社区', trigger: 'change' }],
        housingvalue:[{  required: true, message: '请选择小区', trigger: 'change' }]
      },
      hierarchy:1,
      alterTitle:'修改社区',
      activeName:0
    }
  },
  watch: {
    // filterText(val) {
    //   this.$refs.tree.filter(val)
    // }
  },
  mounted() {
    // 获取省
    this.getRoleIdsDic()
    // 获取树
    this.getTreeList()
    // 获取列表
  },
  methods: {
    /**
     * 批量修改
     */
    alterUser(val){
      
       this.hierarchy = val
       let exclude = {
        excludeId : this.pageParams.exclude
       }
      // 区域
      if(val == 1) {
        this.alterTitle='修改社区'
        if(this.peopleUserFile.length == 0) {
          this.$message.error('请勾选要修改的数据')
          return
        }
        arumDeptList(exclude).then(res => {
          if(res.code == 200) {
            this.options = res.data
          }
        })
      } else {
        this.alterTitle='修改小区'
        if(this.areaUserFile.length == 0) {
          this.$message.error('请勾选要修改的数据')
          return
        }
        // 小区
        arumAddrList(exclude).then(res => {
          if(res.code == 200) {
            this.options = res.data
          }
        })
      }
      this.alterModel = true
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
    },
    /**
     * 提交批量修改
     */
    submitFormwork(){
      let ids = []
      let that = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
      if(that.hierarchy == 1) {
        that.peopleUserFile.forEach(el => {
          ids.push(el.id)
        })
        let data = {
          addrDeptId:that.ruleForm.altervalue,
          addrIds:ids.join(',')
        }
        addrBatchUpdate(data).then(res=>{
          if(res.code == 200) {
            that.alterModel = false
            that.$message.success('修改成功')
            // 获取区域列表
            that.loadUserList()
            // 刷新树
            that.getfindtree()
          }
        })
      } else {
        that.areaUserFile.forEach(el => {
          ids.push(el.id)
        })
        let data = {
          addrId:that.ruleForm.housingvalue,
          addrsIds:ids.join(',')
        }
        addrsBatchUpdate(data).then(res => {
          if(res.code == 200) {
            that.alterModel = false
            that.$message.success('修改成功')
            // 获取楼列表
            that.areaSearch()
            // 刷新树
            that.getfindtree()
          }
        })
      }
        }
      })
    },
    /**
     * 获取树
     */
    getTreeList(){
      findTreeList().then(res => {
        this.treedata = res.data
        this.pageParams.homeCode = res.data[0].children[0].id
      })
      .catch(err => {
        this.$message.error(err.message)
      })
    },
    /**
     * 排序
     */
    sortNumber(row, no){
      if(!row.sortNum){
        row.sortNum = 0
      }
      let data
      if (no == 1) {
         data={
          id:row.id,
          sortNum:row.sortNum,
          table:'gas_zhonglian.aaum_addr'
        }
        updateSortNum(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改排序成功');
              this.loadList(this.pageParams)
            }
        })
      } else if (no==2 || no == 3) {
        data = {
          id: row.id,
          field: 'sort_num',
          sortNum: row.sortNum,
          table: no==2 ? 'gas_zhonglian.arum_addr' : 'gas_zhonglian.arum_addrs'
        }
        updateSortNum1(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改排序成功');
              no==2 ? this.loadUserList() : this.areaSearch()
            }
        })
      } else if(no == 4) {
        data = {
          id: row.id,
          field: 'addr_name',
          sortNum: row.addrName,
          table:'gas_zhonglian.arum_addr'
        }
        updateSortNum1(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改成功');
              no==2 ? this.loadUserList() : this.areaSearch()
            }
        })
      }else if(no == 5) {
        data = {
          id: row.id,
          field: 'addrs_name',
          sortNum: row.addrsName,
          table:'gas_zhonglian.arum_addrs'
        }
        updateSortNum1(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改成功');
              no==2 ? this.loadUserList() : this.areaSearch()
            }
        })
      }else if(no == 6) {
        data = {
          id: row.id,
          field: 'sort_num',
          sortNum: row.sortNum,
          table:'gas_zhonglian.arum_addrss'
        }
        updateSortNum1(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改成功');
              this.unitSearch()
            }
        })
      }else if(no == 7) {
        data = {
          id: row.id,
          field: 'addrss_name',
          sortNum: row.addrssName,
          table:'gas_zhonglian.arum_addrss'
        }
        updateSortNum1(data).then(e => {
          if (e.code === 200) {
              this.$message.success('修改成功');
              this.unitSearch()
            }
        })
      }
    },
    /**
     * 列表复选
     */
    handleSelectionChange(val){
      this.userFile = val
    },
    /**
     * 批量删除
     */
    delUser(){
      if(this.userFile.length == 0) {
        this.$message.error('请勾选要删除的数据')
        return
      }
      let ids = []
      this.userFile.forEach(el => {
        ids.push(el.id)
      })
      this.del(ids)
    },
    // 重置查询条件
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
      }
      this.loadList(this.pageParams)
    },
    areaClose(type) {
      this.areadialogVisible = !this.areadialogVisible
      this.statusdialogVisible = !this.statusdialogVisible //监听值发生变化就关闭它
      if(type==1){
        this.areaRestSearch()
      }else{
        this.areaSearch()
      }
      this.getfindtree()
    },
    unitClose(){
      this.unitdialogVisible = !this.unitdialogVisible
      this.statusdialogVisible = !this.statusdialogVisible
      this.unitSearch()
      // findTree().then(res=>{
      //   this.userTreedata = res.data
      // })
      this.getfindtree()
    },
    // 返回
    close(val) {
      this.statusdialogVisible = !this.statusdialogVisible
      this.dialogVisible = !this.dialogVisible //监听值发生变化就关闭它
      if(this.activeName==1){
        this.restSearch()
        this.getTreeList()
      }else {
        // findTree().then(res=>{
        //   this.userTreedata = res.data
        // })
        this.getfindtree()
        this.handleClick()
      }

    },
    del(row) {
      let dataObject = []
      // 判断是批量删除还单个删除
      if(typeof(row) == 'string'){
        dataObject = [row]
      } else {
        dataObject = row
      }
      let that = this
      this.$confirm(`是否删除数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        addrBatchDelete(dataObject).then(e => {
          if (e.code === 200) {
            that.loadList(that.pageParams)
           
            that.$confirm(e.data, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showCancelButton: false,
              type: 'warning'
            })
            this.getTreeList()
          } else {
            that.$message.error(e.msg)
          }
        })
      })
    },
    updataUser(row) {
      this.updataStatus = 1
      this.updataUserId = row.id
      findUserById({ id: row.id }).then(res => {
        this.form = res.data

        console.log(this.form, this.roleArray_group)
      })
      this.dialogVisible = true
    },
    submitfrom(form) {
      this.$refs[form].validate(valid => {
        if (!valid) return false
        let ajaxData = {}
        this.form.roleIds = Array.from(new Set(this.form.roleIds)) //去重
        Object.assign(ajaxData, this.form)
        ajaxData.homeCode = this.form.homeCode
        saveUser(ajaxData)
          .then(e => {
            if (e.code === 200) {
              this.$message.success(e.msg)
              this.dialogVisible = false
              this.loadList(this.pageParams)
            } else {
              this.$message.error(e.msg)
            }
          })
          .catch(err => {
            this.$message.error(err)
          })
      })
    },
    updatasubmitfrom(form) {
      this.$refs[form].validate(valid => {
        if (!valid) return false
        let ajaxData = {}
        this.form.roleIds = Array.from(new Set(this.form.roleIds)) //去重
        ajaxData.homeCode = this.form.homeCode
        Object.assign(ajaxData, this.form)
        updateUser(ajaxData).then(e => {
          if (e.code === 200) {
            this.$message.success(e)
            this.dialogVisible = false
            this.loadList(this.pageParams)
          } else {
            this.$message.error(e)
          }
        })
      })
    },
    addUser() {
      let roleIds = localStorage.getItem("roleIds").split(',')
      let index = roleIds.findIndex(el => el == '1')
      if(index >= 0) {
        this.$message.info('系统管理员禁止添加！')
        return
      }
      this.updataStatus = 0
      this.dialogVisible = !this.dialogVisible
      this.statusdialogVisible = !this.statusdialogVisible
    },
    addareaUser(){
      let roleIds = localStorage.getItem("roleIds").split(',')
      let index = roleIds.findIndex(el => el == '1')
      if(index >= 0) {
        this.$message.info('系统管理员禁止添加！')
        return
      }
      this.areadialogVisible = !this.areadialogVisible
      this.statusdialogVisible = !this.statusdialogVisible
    },
    addunitUser(){
      let roleIds = localStorage.getItem("roleIds").split(',')
      let index = roleIds.findIndex(el => el == '1')
      if(index >= 0) {
        this.$message.info('系统管理员禁止添加！')
        return
      }
      this.unitdialogVisible = !this.unitdialogVisible
      this.statusdialogVisible = !this.statusdialogVisible
    },
    /**
     * 获取列表数据
     */
    loadList(obj) {
      addrPageList(obj).then(e => {
          this.userList = e.data.records
          this.total = e.data.total
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    /**
     * 查询
     */
    search() {
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    currentChange(current) {
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.currentChange(1)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1;
    },
    /**
     * 树被点击
     */
    clickTree(obj) {
      this.pageParams.deptId = null
      this.pageParams.addrCode = null
      // this.pageParams.current = 1
      // 为数字的时候为父级，不是数字的时候为子集
      if(!isNaN(obj.id)){
        if((obj.id != 1) && (obj.id != 8)){
          this.pageParams.deptId = obj.id
        }
      } else {
        this.pageParams.addrCode = obj.id
      }
      this.addbutton = false
      if(obj.type == 4) {
        this.addbutton = true
      }
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    statusChange(val, row) {
      const statusText = val === 0 ? '禁用' : '开启'
      this.$confirm(`是否${statusText}此用户账号?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      })
        .then(() => {
          usereditStatus({ id: row.id, generalStatus: val })
            .then(() => {
              this.$message.success(`${statusText}成功`)
              this.loadList(this.pageParams)
            })
            .catch(err => {
              this.$message.error(err.message)
              row.generalStatus == 0 ? (row.generalStatus = 1) : (row.generalStatus = 0)
            })
        })
        .catch(() => {
          row.generalStatus == 0 ? (row.generalStatus = 1) : (row.generalStatus = 0)
        })
    },
    /**
     * 导出
     */
    async onDownExcel() {
      let { total, pageParams } = this
      let requestArr = []
      let obj = JSON.parse(JSON.stringify(pageParams))
      obj.current = 1
      while (requestArr.length < total) {
        let e = await findpageList(obj)
        requestArr = [...requestArr, ...e.data.list]
        obj.current++
      }
      let excelData = '\uFEFF'
      excelData += '用户名,用户姓名,部门,手机号码,状态,创建时间'
      excelData += '\n'
      requestArr.forEach(e => {
        excelData += [
          '' + e.username,
          e.realName,
          e.homeName,
          e.phone,
          e.generalStatus === 1 ? '开启' : '禁用',
          e.createTime
        ].join(',')
        excelData += '\n'
      })
      let date = new Date()
      let fileName = `用户管理表格-${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`
      exportMethod(excelData, fileName)
    },
    /**
     * 地址联动下拉-省
     */
    getRoleIdsDic() {
      areaDropDownList({pid:0}).then(res => {
          this.roleArray_group = res.data
        })
        .catch(err => {
          this.roleArray_group = []
        })
    },
    /**
     * 地址联动下拉-市
     */
    getRoleIdsCity() {
      if(this.pageParams.city) {
      this.pageParams.city = ''
      }
      if(this.pageParams.district) {
      this.pageParams.district = ''
      }

      this.search()
      areaDropDownList({pid:this.pageParams.province}).then(res => {
          this.roleArray_city = res.data
        })
        .catch(err => {
          this.roleArray_city = []
        })
    },
    /**
     * 地址联动下拉-区县
     */
    getRoleIdsDistrict() {
      if(this.pageParams.district) {
      this.pageParams.district = ''
      }
      this.search()
      areaDropDownList({pid:this.pageParams.city}).then(res => {
          this.roleArray_area = res.data
        })
        .catch(err => {
          this.roleArray_area = []
        })
    },
    getCompanyFromDic() {
      // getCompanyDic().then(res => {})
    },
    searchTree() {
      this.$refs.tree.filter(this.filterText)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  background: #F3F4F7;
  padding: 10px;
  box-sizing: border-box;
  .clears{
    display: flex;
    flex-direction: row;
    align-items:center ;
    .buttons{
      height: 30px;
      margin-left: 10px;
    }
  }
  .TreeBox {
    padding:0 20px 15px 20px;
    border-right: 1px solid #F1F1F1;
    background-color: #ffffff;
    .titlw{
      font-size: 16px;
      color: #303133;
      margin-bottom: 20px;
      span{
        width: 3px;
        height: 16px;
        border:2px solid #1082FF;
        margin-right:9px;
      }
    }
  }
  .filter-tree {
    height: calc(100vh - 294px);
    padding-top: 20px;
    overflow: auto;
  }
  .TableBox {
    padding: 10px 10px 0;
    height: calc(100vh - 185px);
    // border-radius: 6px;
    background-color: #fff;
    .SearchBox {
      display: flex;
      ::v-deep.el-form-item {
        margin-bottom: 0px;
      }
    }
  }
  .TablePages {
    .AffirmBtn {
      ::v-deep span {
        height: 12px;
        line-height: 12px;
        min-width: 0px;
      }
    }
  }
  .EialogForm {
    .FormBox {
      padding: 0 100px;
      .NotRequired {
        ::v-deep .el-form-item__label {
          padding-left: 10px;
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .TableTool{
    display: flex;
    //margin-top: 20px;
    justify-content: space-between;
  }
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
/deep/.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    // 设置颜色
    background-color: rgba(135, 206, 235, 0.2); // 透明度为0.2的skyblue，作者比较喜欢的颜色 
    color: #409eff; // 节点的字体颜色
    font-weight: bold; // 字体加粗
  }
</style>
