<template>
  <div class="page">
    <div class="create">
      <el-button
          plain
          type="text"
          size="mini"
          style="border: 1px solid #BCBCBC;
                        color: #303133;
                        padding: 0 11px;
                        width:70px;
                        margin:20px;
                        height: 30px;"
          @click="close"
      >返回</el-button
      >
      <div class="detil-code">添加单元</div>
    </div>
    <div class="box">
      <div class="tableRight">
        <el-form
            ref="ruleForm"
            class="inline:false"
            label-position="right"
            :model="form"
            :rules="rules"
            label-width="100px"
        >
          <div class="title">
            <!-- :style="{border:1px solid #1082FF;
                        color: #1082FF;
                        padding: 0 11px;
                        width:70px;
                        height: 30px;}" -->
            <el-button type="primary" plain size="mini"
                       :disabled='this.form.arumAddrss.length >= 20'
                       :style="{background:'#ffffff',color: this.form.arumAddrss.length >= 20 ? '' : '#1082FF',}"
                       @click="addIncreased" >新增单元</el-button>
            <el-button type="text" size="mini" @click="delIncreased" style="border: 1px solid #F52424;
                        color: #F52424;
                        padding: 0 11px;
                        height: 30px;">批量删除</el-button>
            <span style="color: #9A9CA1;font-size: 14px;">（最多可一次性添加20个单元）</span>
            <el-table
                ref="tableList"
                :data="form.arumAddrss"
                style="width: 100%; margin: 15px 0"
                max-height="420"
                border
                :stripe="true"
                @selection-change="handleSelectionChange"
            >
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column
                  label="序号"
                  type="index"
                  width="80">
              </el-table-column>
              <el-table-column
                  prop="addrName"
                  label="单元名称"
                  align="center"
                  show-overflow-tooltip
                  :render-header="addRedStar"
              >

                <template slot-scope="scope">
                  <el-form-item
                      label=""
                      label-width="1"
                      :prop="'arumAddrss.' + scope.$index + '.addrsName'"
                      :rules="[
                      { required: true, message: '请输入单元名称', trigger: 'blur' },
                      { min: 1, max: 80, message: '长度最多80个字符', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                        size="mini"
                        placeholder="请输入单元名称"
                        v-model="scope.row.addrsName"
                        maxlength="80"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                  prop="companyId"
                  label="排序（限制0-9999）"
                  align="center"
                  show-overflow-tooltip
                  :render-header="addRedStar"
              >
                <template slot-scope="scope">
                  <el-form-item
                      label=""
                      label-width="1"
                      :prop="'arumAddrss.' + scope.$index + '.sortNum'"
                      :rules="[{ required: true, message: '请选择所属分公司', trigger: 'change' }]"
                  >
                    <el-input
                        size="mini"
                        type="number"
                        @input="scope.row.sortNum= scope.row.sortNum>9999  ? 9999 : (( scope.row.sortNum< 0) ? 0: scope.row.sortNum)"
                        placeholder="请输入内容"
                        v-model.number="scope.row.sortNum"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="eliminate">清除配置项</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addrssBatchInsert} from "../../RequestPort/peopleUser/people";
export default {
  props:{
    areaId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      form: {
        // 表格数据
        arumAddrss: [{
          id:0,
          addrsName:'',
          sortNum:'0'
        }],
      },
      title: '',
      rules: {
        addrDeptid: { required: true, message: '请选择所所属部门', trigger: 'change' },
      },
      //添加数据
      numberID:0,
      // 暂存
      temporaryFile:[],
    }
  },
  methods: {
    /**
     * 必填的星号
     */
    addRedStar(h,{ column}) {
      return [h('span',{style:'color:red'},'*'),h('span',' '+ column.label)]
    },
    /**
     * 勾选
     */
    handleSelectionChange(val){
      this.temporaryFile = val
    },
    close(){
      this.addresname = ''
      this.$emit('close',{type:1})
    },
    /**
     * 清除配置项
     */
    eliminate() {
      this.form = {
        // 表格数据
        arumAddrss: [{
          id:0,
          addrsName:'',
          sortNum:'0'
        }],
      }
      this.numberID = 0
    },
    /**
     * 新增
     */
    addIncreased() {
      if(this.form.arumAddrss.length < 20) {
        this.numberID += 1
        this.form.arumAddrss.push({
          id:this.numberID,
          addrsName:'',
          sortNum:'0'
        })
      } else {
        this.$message.error('最多10条')
      }
    },
    /**
     * 批量删除
     */
    delIncreased(){
      if(this.temporaryFile.length == 0) {
        this.$message.error('请先勾选数据')
        return
      }
      this.temporaryFile.forEach(el => {
        this.form.arumAddrss.forEach((item,index) =>{
          if(el.id ==item.id ){
            this.form.arumAddrss.splice(index,1)
          }
        })
      })
    },
    /**
     * 提交
     */
    save() {
      if(this.form.arumAddrss.length == 0) {
        this.$message.error('至少添加一条片区信息')
        return
      }
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.form.pid = this.areaId
          addrssBatchInsert(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.form = {
                // 表格数据
                arumAddrss: [{
                  id:0,
                  addrsName:'',
                  sortNum:'0'
                }],
              }
              this.$emit('close',{type:0})
            }
          })
        }
      })
    },
    setMapClick() {
      this.$refs.subMap.getLocation()
    },
  }
}
</script>
<style lang="scss" scoped>
.map-row {
  position: relative;
  .btn-draw {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.page {
  width: 100%;
  height: calc(100vh - 175px);
  overflow: auto;
  background: #fff;
  ::v-deep{
    .el-form-item {
      margin-bottom: 0;
    }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    // height: 58px;
    // border-bottom: 1px solid #ecedf1;
    // background-color: burlywood;
    // display: flex;
    // flex-direction: row;
    align-items: center;
    .create_size {
      font-size: 18px;
      color: #303133;
      font-weight: bold;
      margin-left: 20px;
      span{
        width: 3px;
        height: 16px;
        border:1px solid #1082FF;
        margin-right:9px;
      }
    }
  }
  .box {
    width: 96%;
    margin:20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;

      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        // border: 1px solid #ecedf1;
        // padding: 10px;
        margin-bottom: 20px;
        .title_box{
          display: flex;
          flex-direction: row;
          flex-wrap:wrap;
          .el-form-item{
            margin: 10px 0;
          }
        }

      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.img {
  height: 12px;
  border: 1px solid #3f74d5;
  margin-top: 6px;
  margin-right: 5px;
}
.titleName {
  font-size: 14px;
  padding-bottom: 15px;
  font-weight: 400;
  color: #303133;
}
.addBtn {
  margin: 0 10px;
}
.detil-code{
  margin-left: 20px;
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
</style>
