import {findTree, addrsPageList, addrPageList,addrsBatchDelete,addrBatchDelete,addrssPageList,addrssBatchDelete} from '../../RequestPort/peopleUser/people'
import {areaDropDownList} from "@/RequestPort/induction";
export default {
    data() {
        return{
            chucunObj:{},
            chucunnodeObj:null,
            userfilterText:'',
            showEare:1,
            areadialogVisible:false,
            activeName:'1',
            defaultKeys:['8'],
            areaId:'',
            pid:'',
            areaTotal:null,
            usertotal:null,
            areaUserFile:[],
            peopleUserFile:[],
            userTreedata:[],
            areaUserList:[],
            peopleuserList:[],
            userPageParams: {
                current: 1,
                size: 100,
            },
            areaPageParams: {
                current: 1,
                size: 100,
                addrsName:''
            },
            userroleArray_city:[],
            userroleArray_area:[],
            // 单元
            unitList:[],
            unitTotal:0,
            unitPageParams:{
                current: 1,
                size: 100,
            },
            unitpid:'',
            unitUserFile:[]
        }
    },
    watch:{
        // userfilterText(val) {
        //     this.$refs.userTree.filter(val)
        // }
    },
    methods:{
        userSearchTree() {
            this.$refs.userTree.filter(this.userfilterText)
        },
        peopledelUser(){
            if(this.peopleUserFile.length == 0) {
                this.$message.error('请勾选要删除的数据')
                return
            }
            let ids = []
            this.peopleUserFile.forEach(el => {
                ids.push(el.id)
            })
            this.peopledel(ids)
        },
        peopledel(row){
            let dataObject = []
            // 判断是批量删除还单个删除
            if(typeof(row) == 'string'){
                dataObject = [row]
            } else {
                dataObject = row
            }
            let that = this
            this.$confirm(`是否删除数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                addrBatchDelete(dataObject).then(e => {
                    if (e.code === 200) {
                        console.log(e.data)
                        that.UserclickTree(that.chucunObj,that.chucunnodeObj)
                        
                        that.getfindtree()
                        that.$confirm(e.data, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: false,
                            type: 'warning'
                        })
                        that.userSearch()
                    } else {
                        that.$message.error(e.msg)
                    }
                })
            })
        },
        peoplehandleSelectionChange(val){
            this.peopleUserFile = val
        },
        peoplegetRoleIdsDistrict() {
            if(this.userPageParams.district) {
                this.userPageParams.district = ''
            }
            this.loadUserList()
            areaDropDownList({pid:this.userPageParams.city}).then(res => {
                this.userroleArray_area = res.data
            })
                .catch(err => {
                    this.userroleArray_area = []
                })
        },
        peoplegetRoleIdsCity(){
            if(this.userPageParams.city) {
                this.userPageParams.city = ''
            }
            if(this.userPageParams.district) {
                this.userPageParams.district = ''
            }
            this.loadUserList()
            areaDropDownList({pid:this.userPageParams.province}).then(res => {
                this.userroleArray_city = res.data
            })
                .catch(err => {
                    this.userroleArray_city = []
                })
        },
        toquyu(row){
            this.showEare = 2
            this.areaPageParams.pid = row.id
            this.pid = row.id
            addrsPageList(this.areaPageParams).then(res=>{
                this.areaUserList = res.data.records
                this.areaTotal = res.data.total
            })
        },
        // 管理单元
        toquyuunit(row){
            this.showEare = 3
            this.unitPageParams.pid = row.id
            this.unitpid = row.id
            this.addrssPageLists(this.unitPageParams)
        },
        handleAreaSelectionChange(val){
            this.areaUserFile = val
        },
        handleUnitSelectionChange(val){
            this.unitUserFile = val
        },
        delArea(row){
            let dataObject = []
            // 判断是批量删除还单个删除
            if(typeof(row) == 'string'){
                dataObject = [row]
            } else {
                dataObject = row
            }
            let that = this
            this.$confirm(`是否删除数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                addrsBatchDelete(dataObject).then(e => {
                    if (e.code === 200) {
                        that.getfindtree()
                        that.UserclickTree(that.chucunObj,that.chucunnodeObj)
                        that.$confirm(e.data, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: false,
                            type: 'warning'
                        })
                        that.areaSearch()
                    } else {
                        that.$message.error(e.msg)
                    }
                })
            })
        },
        // 删除单元
        delunit(row){
            let dataObject = []
            // 判断是批量删除还单个删除
            if(typeof(row) == 'string'){
                dataObject = [row]
            } else {
                dataObject = row
            }
            let that = this
            this.$confirm(`是否删除数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                addrssBatchDelete(dataObject).then(e => {
                    if (e.code === 200) {
                        that.getfindtree()
                        that.UserclickTree(that.chucunObj,that.chucunnodeObj)
                        that.$confirm(e.data, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            showCancelButton: false,
                            type: 'warning'
                        })
                        that.unitSearch()
                    } else {
                        that.$message.error(e.msg)
                    }
                })
            })
        },
        areaDelUser(){
            if(this.areaUserFile.length == 0) {
                this.$message.error('请勾选要删除的数据')
                return
            }
            let ids = []
            this.areaUserFile.forEach(el => {
                ids.push(el.id)
            })
            this.delArea(ids)
        },
        // 批量删除
        unitDelUser(){
            if(this.unitUserFile.length == 0) {
                this.$message.error('请勾选要删除的数据')
                return
            }
            let ids = []
            this.unitUserFile.forEach(el => {
                ids.push(el.id)
            })
            this.delunit(ids)
        },
        //区域分页
        areacurrentChange(current) {
            this.areaPageParams.current = current
            addrsPageList(this.areaPageParams).then(res=>{
                this.areaUserList = res.data.records
                this.areaTotal = res.data.total
            })
        },
        areahandleSizeChange(val) {
            this.areaPageParams.size = val
            this.areacurrentChange(1)
        },
        //小区分页
        usercurrentChange(current) {
            this.userPageParams.current = current
            this.loadUserList()
        },
        userhandleSizeChange(val) {
            this.userPageParams.size = val
            this.usercurrentChange(1)
        },
        handleClick(){
            if(this.activeName==1){
                this.pageParams.size = 100
                this.pageParams.current = 1
                this.loadList(this.pageParams)
            }else {
                this.userPageParams.size = 100
                this.userPageParams.current = 1
               this.loadUserList()
            }
            this.dialogVisible = false
            this.areadialogVisible = false
            this.statusdialogVisible = false
        },
        userSearch() {
            this.userPageParams.current = 1
            this.loadUserList()
        },
        // 重置查询条件
        userRestSearch() {
            this.userPageParams = {
                current: 1,
                size: 100,
            }
            this.loadUserList()
        },
        areaSearch() {
            this.areaPageParams.current = 1
            addrsPageList(this.areaPageParams).then(res=>{
                this.areaUserList = res.data.records
                this.areaTotal = res.data.total
            })
        },
        // 重置查询条件
        areaRestSearch() {
            this.areaPageParams = {
                current: 1,
                size: 100,
                pid:this.pid
            }
            addrsPageList(this.areaPageParams).then(res=>{
                this.areaUserList = res.data.records
                this.areaTotal = res.data.total
            })
        },
        loadUserList(){
            addrPageList(this.userPageParams).then(res=>{
                this.peopleuserList = res.data.records
                this.usertotal = res.data.total
            })
        },
        UserfilterNode(value, data) {
            if (!value) return true
            return data.name.indexOf(value) !== -1;
        },
        handleArea(type,trueId){
            if(type==4){
                this.showEare = 2
                this.areaPageParams.id =''
                this.areaPageParams.pid = trueId
                this.pid = trueId
                addrsPageList(this.areaPageParams).then(res=>{
                    this.areaUserList = res.data.records
                    this.areaTotal = res.data.total
                })
            }else if(type==5){ //5时候展示新的table
                this.showEare = 2
                this.areaId = trueId
                this.areaPageParams.pid =''
                this.areaPageParams.id = trueId
                addrsPageList(this.areaPageParams).then(res=>{
                    this.areaUserList = res.data.records
                    this.areaTotal = res.data.total
                })
            } else {
                this.showEare = 1
                this.userPageParams.deptId=''
                this.userPageParams.addrId = trueId
                this.userPageParams.type = type
                this.loadUserList(this.userPageParams)
            }
        },
        UserclickTree(obj,node) {
            this.chucunObj = obj
            this.pageParams.homeCode = obj.id
            this.pageParams.homeName = obj.name
            this.pageParams.exclude = obj.trueId
            this.chucunnodeObj = node
            this.userPageParams.current = 1
            this.unitPageParams.current = 1
            this.pageParams.current = 1
            this.areaPageParams.current = 1
            this.unitpid = ''
            this.addbuttonmin = false
            // 为数字的时候为父级，不是数字的时候为子集
            // if(obj&&!isNaN(obj.id)){
            //     this.showEare = false
            //     this.userPageParams.addrId = ''
            //     if((obj.id != 1) && (obj.id != 8)){
            //         this.userPageParams.deptId = obj.trueId
            //         this.loadUserList(this.userPageParams)
            //     }else{
            //         this.userPageParams.deptId = ''
            //         this.loadUserList(this.userPageParams)
            //     }
            // } else if(node&&node.parent&&node.parent.data) {
            //     this.pid = node.parent.data.trueId+''
            //     this.handleArea(obj.type,obj.trueId)
            // }
            if(obj.type < 4) {
                if(obj.type == 1) {
                    this.pageParams.findTreeid = obj.children[0].id
                    this.pageParams.findTreeName = obj.children[0].name
                }
                else if(obj.type == 2) {
                    this.pageParams.findTreeid = obj.id
                    this.pageParams.findTreeName = obj.name
                }
                this.showEare = 1
                this.userPageParams.addrId = ''
                // if((obj.id != 1) && (obj.id != 8)){
                    this.userPageParams.deptId = obj.trueId
                    if(this.userPageParams.type) {
                        delete this.userPageParams.type
                    }
                    this.loadUserList(this.userPageParams)
                // }else{
                //     this.userPageParams.deptId = ''
                //     this.loadUserList(this.userPageParams)
                // }
            } else if(obj.type >= 4 && obj.type<5 ) {
                this.showEare = 2
                this.pid = node.parent.data.trueId+''
                this.handleArea(obj.type,obj.trueId)
            } else if(obj.type == 6 ) {
                this.addbuttonmin = true
                this.showEare = 3
                this.pid = ''
                this.handleArea(obj.type,obj.trueId)
            }
            else {
                this.showEare = 3
                this.unitpid = obj.trueId+''
                this.unitPageParams.pid = obj.trueId
                this.addrssPageLists(this.unitPageParams)
            }

        },
        /**
         * 单元列表
         */
        addrssPageLists(val){
            addrssPageList(val).then(res => {
                if(res.code == 200) {
                    this.unitList = res.data.records
                    this.usertotal = res.data.total
                }
            })
        },
        /**
         * 单元查询
         */
        unitSearch(){
            this.unitPageParams.current = 1
            this.addrssPageLists(this.unitPageParams)
        },
        // 单元重置查询条件
        unitRestSearch() {
            this.unitPageParams = {
                current: 1,
                size: 100,
                pid:this.unitpid
            }
            this.addrssPageLists(this.unitPageParams)
        },
        /**
         * 分页
         */
        unithandleSizeChange(val){
            this.unitPageParams.size = val
            this.unitPageParams.current = 1
            this.addrssPageLists(this.unitPageParams)
        },
        unitcurrentChange(current){
            this.unitPageParams.current = current
            this.loadUserList(this.unitPageParams)
        },
        /**
         * 获取民用树
         */
        getfindtree(){
            findTree().then(res=>{
                this.userTreedata = res.data
                this.userTreedata.forEach(el => {
                    if(el.type != 4 && el.children && el.children.length > 0) {
                        el.children.forEach(el1 => {
                            if(el1.type != 4 && el1.children && el1.children.length > 0) {
                                el1.children.forEach((el2,index1) => {
                                    if(el2.type != 4 && el2.children && el2.children.length > 0) {
                                        el2.children.forEach((el3,index) => {
                                            if(el3.type == 4) {
                                                el3.name = (index+1)+'.'+el3.name
                                            }
                                        })
                                    }
                                    else if(el2.type == 4) {
                                        el2.name = (index1+1)+'.'+el2.name
                                    }
                                })
                            }
                        })
                    }
                })
            })
        },
    },
    mounted() {
        this.getfindtree()
        this.handleClick()
    }
}